import	Form		from './Form'
import	Game		from './Game'
import	Admin		from './Admin'

import './App.css'

function App() {
	const	pathname = window.location.pathname

	if (pathname === '/game')
		return <Game />
	if (pathname === '/admin')
		return <Admin />
	return <Form />

}

export default App;