const	APIURLROOT = process.env.REACT_APP_API_URL || 'https://api.barrel-pub.com'

const	APIFETCH = (method, link, data) =>
{
	const	headers = {'Content-Type': 'application/json'}
	const	options = {headers, method}

	if (data)
		options.body = JSON.stringify(data)

	return (
		fetch(`${APIURLROOT}${link}`, options)
		.then(response => response.json())
		.then(json => json ? json : {error: 'no response'})
		.catch(err => {
			console.warn(err)
			return {error: 'no response'}
		})
	)
}

const	DOWNLOAD = (link, name, type = 'mp4') => {
	const	options = {method: 'GET'}

	return (
		fetch(link, options)
		.then(resp => resp.blob())
		.then(blob => {
			const url = window.URL.createObjectURL(blob)
			const a = document.createElement('a')

			a.style.display = 'none'
			a.href = url
			a.download = `${name || 'document'}.${type}`

			document.body.appendChild(a)
			a.click()
			// window.URL.revokeObjectURL(url)
			return ({status: true})
		})
		.catch(err => {
			console.log(err)
			return ({status: false})
		})
	)
}

export	const	getGame = idGame => APIFETCH('GET', `/game/${idGame}`)
export	const	postGame = (idGame, data) => APIFETCH('POST', `/game/${idGame}`, data)

export	const	login = data => APIFETCH('POST', `/login`, data)
export	const	getStats = data => APIFETCH('POST', '/stats', data)

export	const	deleteGame = data => APIFETCH('POST', '/delete', data)

export	const	downloadVideo = DOWNLOAD
